.Main::-webkit-scrollbar {
  display: none;
}
.css-16actsd-MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar {
  /* width: 5px;
  height: 5px;
  background-color: #c2c2c2; */
  display: none;
}
::-webkit-scrollbar {
  width: 5px !important;
  height: 1px !important;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: #3672bc60 !important;
  border-radius: 50px !important;
}

::-webkit-scrollbar-track {
  background: #3672bc10 !important;
}
